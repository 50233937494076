import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import CloseTicketForm from "../../components/CloseTicketForm";
import { API, getRedFlags } from "./StationMonitoring.api";
import moment from "jalali-moment";
import RateInfo from "../../components/RateInfo";
import CustomFilterTicket from "../../../customFilters/CustomFilterTicket";

let fetchDataFunction;

const StationMonitoring = () => {
  const [changeStateVisible, setChangeStateVisible] = useState(false);
  const [rateInfoPopup, setRateInfoPopup] = useState(false);

  const { t } = useTranslation();
  const columns = [
    {
      title: t("general::name"),
      dataIndex: "senderExtraInfo",
      key: "senderExtraInfo",
      render: (text, record) => {
        if (record.senderExtraInfo.firstName && record.senderExtraInfo.lastName) {
          return 'No Name'
        }
        return `${
          record.senderExtraInfo && record.senderExtraInfo.firstName
            ? record.senderExtraInfo.firstName
            : ""
        } ${
          record.senderExtraInfo && record.senderExtraInfo.lastName
            ? record.senderExtraInfo.lastName
            : ""
        }`;
      },
    },
    // {
    //   title: t("administration::subject"),
    //   dataIndex: "subject",
    //   key: "subject",
    //   ...GetColumnSearch("subject", "Subject"),
    // },
    {
      title: t("general::category"),
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: t("general::createdDateMilli"),
      dataIndex: "createdDateMilli",
      key: "createdDateMilli",
      filter: "fromTo",
      sorter: true,
      render: (text) => {
        return moment(text).format("YYYY/MM/DD");
      },
    },
    {
      title: t("support::state"),
      dataIndex: ["state", "current", "state"],
      key: "state",
      customFilter: CustomFilterTicket,
      render: (text) => {
        return <span style={{
          color: text === 'OPENED' ? 'red' : 'green'
        }}>{text}</span>
      },
    },
    // {
    //   title: t("general::rate"),
    //   dataIndex: "rateDto",
    //   key: "rateDto",
    //   render: (text) => {
    //     if (text) {
    //       return (
    //         <>
    //           {`${t("general::rate")}: ${text.rating}`}
    //           <Button
    //             type="primary"
    //             onClick={(event) => setRateInfoPopup(text)}
    //             className="mr-1"
    //           >
    //             <InfoCircleTwoTone style={{ fontSize: "20px" }} />
    //           </Button>
    //         </>
    //       );
    //     }
    //     return t("general::notRated");
    //   },
    // },
  ];

  const history = useHistory();

  return (
    <Container classNames="contents">
      <Crud
        otherAction={[
          {
            name: t("detail"),
            func: (record) => {
              history.push(`${window.location.pathname}/messages/${record.id}`);
            },
          },
          {
            name: t("changeState"),
            func: (record, fetchData) => {
              setChangeStateVisible(record);
              fetchDataFunction = fetchData;
            },
          },
        ]}
        get={{
          apiService: (params) => {
            return getRedFlags(params);
          },
          columns,
        }}
        remove={{
          api: API,
        }}
      />
      <CloseTicketForm
        fetchData={fetchDataFunction}
        changeStateVisible={changeStateVisible}
        setChangeStateVisible={setChangeStateVisible}
      />
      <RateInfo
        rateInfoPopup={rateInfoPopup}
        setRateInfoPopup={setRateInfoPopup}
      />
    </Container>
  );
};

export default StationMonitoring;
